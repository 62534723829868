<template>
  <label
    :for="id"
    :class="labelClasses"
  >
    {{label}}
    <em
      v-if="required"
      class="required-mark">*</em>
  </label>
</template>

<script>
/**
 * 기본 Label 요소 컴포넌트
 */
export default {
  name: 'BaseLabelElement',
  props: {
    /**
     * 레이블
     */
    label: {
      type: String,
    },
    /**
     * 타입
     * @values block, inline
     */
    type: {
      type: String,
      default: 'block',
      validator: (value) => [
        'block',
        'inline',
      ].indexOf(value) !== -1,
    },
    /**
     * ID
     */
    id: {
      type: String,
    },
    /**
     * 필수 여부
     */
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    labelClasses() {
      return {
        'inp-title': this.type === 'inline',
        inline: this.type === 'inline',
      };
    },
  },
};
</script>

<style scoped>
  label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #333;
  }
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BaseLabelElement id="subject34" label="제목" required />
```

인라인 사용 예시 :
```jsx
<BaseLabelElement
  type="inline"
  label="신청구분"
/>
<BaseRadioButton
  name="radioApplicationCategory"
>개인사업자</BaseRadioButton>
<BaseRadioButton
  name="radioApplicationCategory"
>예비창업자</BaseRadioButton>
```
</docs>
