<template>
  <div
    class="input-container"
    :class="containerClasses"
  >
    <input
      type="text"
      :id="id"
      ref="input"
      class="input-border"
      :class="inputClasses"
      v-bind="$attrs"
      :value="value"
      @input="onInput"
    >
    <span class="focus-border">
      <i></i>
    </span>
  </div>
</template>

<script>
/**
 * https://nosir.github.io/cleave.js/
 * https://github.com/nosir/cleave.js/
 * https://github.com/nosir/cleave.js/issues/459#issuecomment-569586240
 * options : https://github.com/nosir/cleave.js/blob/master/doc/options.md
 */
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.kr';

/**
 * 기본 Input 요소 컴포넌트
 */
export default {
  name: 'BaseInputElement',

  /**
   * https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
   */
  inheritAttrs: false,

  props: {
    /**
     * 타입
     * @values text, currency, number, tel
     */
    type: {
      type: String,
      default: 'text',
      validator: (value) => [
        'text',
        'currency',
        'number',
        'tel',
      ].indexOf(value) !== -1,
    },

    /**
     * 입력값
     * @model
     */
    value: {
      type: [String, Number],
    },

    /**
     * ID
     */
    id: {
      type: String,
    },

    /**
     * 아이콘
     * @values none, homepage, sns, media
     */
    icon: {
      type: String,
      default: 'none',
      validator: (value) => [
        'none',
        'homepage',
        'sns',
        'media',
      ].indexOf(value) !== -1,
    },

    /**
     * 폭
     * @values full, half, third
     */
    width: {
      type: String,
      default: 'full',
      validator: (value) => [
        'full',
        'half',
        'third',
      ].indexOf(value) !== -1,
    },

    /**
     * 입력 옵션 (raw: 원래 입력값(타입) 유지 여부, positiveOnly: 양수만 가능 여부)
     */
    inputOptions: {
      type: Object,
      default() {
        return { raw: true, positiveOnly: true };
      },
    },

    /**
     * 유효성 검사 실패 여부
     */
    invalid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formattedValue: undefined,
      rawValue: undefined,
    };
  },

  computed: {
    containerClasses() {
      return {
        'input-md': this.width === 'half',
        'input-sm': this.width === 'third',
        error: this.invalid,
      };
    },

    inputClasses() {
      return {
        homepage: this.icon === 'homepage',
        sns: this.icon === 'sns',
        media: this.icon === 'media',
      };
    },
  },

  watch: {
    value(newValue) {
      if (this.type !== 'text') {
        this.cleave.setRawValue(newValue);
      }
    },
  },

  mounted() {
    // text 타입이 아닌 경우
    if (this.type !== 'text') {
      let cleaveOption;
      switch (this.type) {
        case 'currency':
          cleaveOption = {
            numeral: true,
            numeralDecimalScale: 0,
          };
          break;
        case 'number':
          cleaveOption = {
            numeral: true,
            numeralPositiveOnly: this.inputOptions && this.inputOptions.positiveOnly,
            numeralThousandsGroupStyle: 'none',
            numeralDecimalScale: 0,
          };
          break;
        case 'tel':
          cleaveOption = {
            phone: true,
            phoneRegionCode: 'kr',
            delimiter: '-',
          };
          break;
        case 'date':
          cleaveOption = {
            numeral: true,
            numeralThousandsGroupStyle: 'none',
            stripLeadingZeroes: false,
          };
          break;
        default:
          cleaveOption = {};
      }

      this.cleave = new Cleave(this.$refs.input, {
        ...cleaveOption,
        onValueChanged: this.onValueChanged,
      });

      if (this.value) {
        this.cleave.setRawValue(this.value);
      }
    }
  },

  methods: {
    onInput(event) {
      if (this.type === 'text') {
        // text 타입인 경우

        /**
         * 입력 이벤트
         * @event input
         * @property {string|number} 변경된 입력값
         */
        this.$emit('input', event.target.value);
      } else {
        // text 타입이 아닌 경우
        // onValueChanged 에서 input 이벤트 처리
      }
    },
    onValueChanged({ target }) {
      if (target.value === '-') return; // 음수 경우 고려

      this.formattedValue = target.value;

      let value;

      if (this.type === 'currency' || this.type === 'number') {
        // currency, number 타입인 경우
        // 원래 타입(string 또는 number)도 유지
        if (typeof this.value === 'string') {
          this.rawValue = target.rawValue;
        } else {
          // target.rawValue가 ''인 경우 0 대신에, undefined을 this.rawValue에 셋팅
          this.rawValue = target.rawValue ? Number(target.rawValue) : undefined;
        }

        const toRaw = this.inputOptions && this.inputOptions.raw;
        value = toRaw ? this.rawValue : this.formattedValue;
      } else {
        // text, tel 타입인 경우 그대로
        value = this.formattedValue;
      }

      /**
       * 입력 이벤트
       * @event input
       * @property {string|number} 변경된 입력값
       */
      this.$emit('input', value);
    },
  },

  beforeDestroy() {
    if (this.cleave) {
      this.cleave.destroy();
      this.cleave = null;
    }
  },
};
</script>

<style scoped>
  .input-container .input-border ~ .focus-border:before,
  .input-container .input-border ~ .focus-border:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #000;
    transition: 0.2s;
  }

  .input-container .input-border ~ .focus-border:after {
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
  }

  .input-container .input-border ~ .focus-border i:before,
  .input-container .input-border ~ .focus-border i:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 0;
    background-color: #000;
    transition: 0.2s;
  }

  .input-container .input-border ~ .focus-border i:after {
    right: auto;
    left: 0;
    top: auto;
    bottom: 0;
  }

  .input-container .input-border:focus ~ .focus-border:before,
  .input-container .input-border:focus ~ .focus-border:after {
    width: 100%;
  }

  .input-container .input-border:focus ~ .focus-border:after {
    transition-delay: 0.2s;
  }

  .input-container .input-border:focus ~ .focus-border i:before,
  .input-container .input-border:focus ~ .focus-border i:after {
    height: 100%;
  }

  .input-container .input-border:focus ~ .focus-border i:before {
    transition-delay: 0.1s;
  }

  .input-container .input-border:focus ~ .focus-border i:after {
    transition-delay: 0.3s;
  }
</style>

<!-- https://vue-styleguidist.github.io/docs/Documenting.html -->
<docs>

기본 사용 예시 :
```jsx
<BaseInputElement
  value="가나다ABC"
  placeholder="입력해주세요..."
/>
<span class="input-desc">조금만 입력해 주세요.</span>
```

유효성 검사 실패 여부 (invalid) 예시 :
```jsx
<BaseInputElement
  value=""
  placeholder="이름"
  :invalid="true"
/>
<span class="input-desc">본인의 이름을 입력해 주세요.</span>
<span class="input-error-desc">이름을 입력해 주세요.</span>
```

한 줄에 2개 Input 사용 예시 :
```jsx
<ul>
  <li class="form-row">
    <BaseLabelElement
      id="company_name"
      label="업체명"
      required
    />
    <BaseInputElement
      width="half"
      id="company_name"
      placeholder="업체명 국문 입력"
      required
    />
    <BaseInputElement
      width="half"
      id="company_name_en"
      placeholder="업체명 영문 입력"
    />
  </li>
</ul>
```


한 줄에 3개 Input 사용 예시 :
```jsx
<ul>
  <li class="form-row">
    <BaseLabelElement
      id="year"
      label="일자"
    />
    <BaseInputElement
      width="third"
      type="number"
      id="year"
      placeholder="연"
      required
    />
    <BaseInputElement
      width="third"
      type="number"
      id="month"
      placeholder="월"
    />
    <BaseInputElement
      width="third"
      type="number"
      id="day"
      placeholder="일"
    />
  </li>
</ul>
```

아이콘 사용 예시 :
```jsx
<BaseInputElement icon="homepage" />
<BaseInputElement icon="sns" />
<BaseInputElement icon="media" />
```
</docs>
